
import { defineComponent, onMounted } from 'vue';
import { mapGetters } from 'vuex';
import store from '@/store';
import Header from '@/components/Header.vue';
import QuizResultsContainer from '@/components/QuizResultsContainer.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'QuizResults',
  computed: {
    ...mapGetters(['getResults']),
  },
  setup() {
    const results = store.getters.getResults;
    onMounted(() => {
      if (results.length === 0) {
        // @TODO redirect to quiz if there are no results in the store.
        // this.$router.push({ name: 'Quiz' });
      }
    });
    return { results };
  },
  components: {
    Header,
    QuizResultsContainer,
    Footer,
  },
});
