/* eslint-disable global-require */
/* eslint-disable max-len */
import PlainContent from '@/types/PlainContent';

const QuizResultsContent: PlainContent = {
  title: 'The answer is... <br />over 25,000',
  body: `<p>
  We set ambitious targets at the beginning of the HPC project and we’re proud to say that
  our efforts have not been in vain.
</p>

<p>
  And we’re not stopping there. We plan to provide a multitude of opportunities, even once
  construction has finished.
</p>

<p>
  We want you to be a part of our lasting legacy, so use the button below to begin the
  questionnaire and we’ll find something to suit you!
</p>

<p>
  Or, if you want to learn more about the range of opportunities available, you can use
  the ‘Explore job sectors’ button below.
</p>`,
};

export default QuizResultsContent;
