
import { defineComponent } from 'vue';
import PlainContent from '../types/PlainContent';
import QuizResultsContent from '@/content/QuizResultsContent';

export default defineComponent({
  name: 'QuizResultsContainer',
  setup() {
    const content: PlainContent = QuizResultsContent;
    return {
      content,
    };
  },
});
